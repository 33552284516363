import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

const Page = () => (
  <Layout>
    <Seo title="Rutiner" />
    <div className="longtext">
      <h2>Rutiner</h2>
      <h3>Bygge og spille av rutiner</h3>
      <p>
        Start med å se{" "}
        <a href="https://www.mente.app/features/routines">
          filmen som ligger her
        </a>
      </p>

      <h3>Hvor ligger rutinene mine?</h3>
      <p>Som standard finner du de igjen under:</p>
      <ul>
        <li>
          Bli bedre -&gt; Bygg gode rutiner -&gt; Bygg din egen rutine, eller
        </li>
        <li>Biblioteket -&gt; Rutiner</li>
      </ul>

      <p>
        Bruker du plussknappen i disse listene vil du få forslag til rutinesteg
        du kan legge til, som vist i filmen over.{" "}
      </p>

      <p>Merk at:</p>
      <ul>
        <li>
          du kan legge rutinesteg i hvilken liste du vil, men det kan være fint
          å ha de under Biblioteket -&gt; Rutiner for å samle de et sted
        </li>
        <li>
          rutiner automatisk dukker opp i "I dag" dersom en av stegene har er
          planlagt med dagens dato
        </li>
      </ul>

      <h3>Hvordan kan jeg lage rutiner som ikke gjentas hver dag?</h3>
      <ol>
        <li>Åpne oppgaven</li>
        <li>Trykk på ... og deretter "Gjenta"</li>
        <li>Endre til ønsket intervall</li>
      </ol>

      <p>
        Det vil si at du kan blande ulikt intervall på stegene i samme liste,
        men det er bare stegene ble dagens dato som blir spilt av når du trykker
        på "Play"-knappen.
      </p>

      <h3>Hvordan kan jeg utsette/hoppe over rutiner?</h3>
      <p>
        Du kan flytte rutinesteg på samme måte som du flytter vanlige oppgaver:
      </p>

      <ol>
        <li>Gå inn på ønsket rutineliste</li>
        <li>Sveip/høyreklikk på stegene du vil flytte til en annen dato. </li>
      </ol>

      <h3>Egendefinerte steg i rutinene dine</h3>
      <p>Du kan gjøre om enhver oppgave til et rutinesteg dersom du:</p>

      <ol>
        <li>Åpner oppgaven</li>
        <li>Trykker på ... og deretter "Handling"</li>
        <li>Velg hva du ønsker skal skje</li>
      </ol>

      <p>Normalt vil du også at oppgaven gjentas, noe du setter ved:</p>
      <ol>
        <li>Åpne oppgaven</li>
        <li>Trykker på ... og deretter "Gjenta"</li>
      </ol>
    </div>
  </Layout>
)

export default Page
